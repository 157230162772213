/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const assets = types.model('assets', {
  content_id: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  content_type: types.maybeNull(types.string),
  book_id: types.maybeNull(types.string),
  updated_time: types.maybeNull(types.string),
  product_id: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  output_xls_s3path: types.maybeNull(types.string)
});

const BulkAssets = types
  .model('BulkAssets', {
    data: types.optional(types.array(assets), []),
    dataById: types.optional(types.array(assets), [])
  })
  .actions((self) => ({
    fetchAssets() {
      Framework.getEventManager().publish(constants.GET_PULSE_ASSETS);
    },
    setAssets(response) {
      applySnapshot(self.data, response);
      Framework.getEventManager().publish(constants.SET_PULSE_ASSETS);
    },
    fetchStatus(productId) {
      Framework.getEventManager().publish(constants.GET_PULSE_ASSETS_BY_ID, { productId });
    },
    setAssetsById(response) {
      applySnapshot(self.dataById, response);
      Framework.getEventManager().publish(constants.SET_PULSE_ASSETS_BY_ID);
    }
  }));

export default BulkAssets;
