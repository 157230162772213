/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { withStyles } from '@material-ui/core';
import { Switch, Route, Link } from 'react-router-dom';
import { shapes } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Header from '../../common/header/Header';
// import Footer from '../../common/Footer';
// import BulkIngestionComp from './components/BulkIngestionComp';
import BulkAction from './components/BulkActionComp';
import BulkMigration from './components/BulkMigration';
import UsersPermissions from '../users/models/UsersPermissions';
import BulkView from './components/BulkView';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});
@inject('userPermissions')
@observer
class BulkUtilityContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    history: PropTypes.object.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0
    };
  }

  handleTabChange = (e, value) => {
    this.setState({
      tabValue: value
    });
  };

  render() {
    const { classes, language, history, userPermissions } = this.props;
    const { tabValue } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    return (
      <>
        <Header />
        <main className={classes.contentWrapper}>
          {permissionsList.includes('bulk_can_view') ? (
            <Paper elevation={3}>
              {!history.location.pathname.includes('/bulkutility/action') && (
                <AppBar position="static" color="default">
                  <Tabs
                    // value={0}
                    value={tabValue}
                    onChange={(e, data) => this.handleTabChange(e, data)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="BulkUtility Status Tabs"
                  >
                    <Tab
                      label={<FormattedMessage {...language.getText('bulkutility.BULK_MIGRATION')} />}
                      component={Link}
                      to="/bulkutility/migration"
                    />
                    <Tab
                      label={<FormattedMessage {...language.getText('bulkutility.TITLE_BULKINGESTION')} />}
                      component={Link}
                      to="/bulkutility/ingestion"
                    />
                  </Tabs>
                </AppBar>
              )}
            </Paper>
          ) : (
            ''
          )}
          <Switch>
            <Route path="/bulkutility/ingestion" exact render={() => <BulkView />} />
            <Route path="/bulkutility/action" exact render={() => <BulkAction />} />
            <Route path="/bulkutility/migration" exact render={() => <BulkMigration />} />
          </Switch>
        </main>
        {/* <Footer /> */}
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(observer(BulkUtilityContainer)));
