/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import Entitlement from './models/Entitlement';
import Header from '../../common/header/Header';
import EntitlementHome from './components/EntitlementHome';
import Loader from '../../common/Loader';
import NotificationModel from '../../common/components/notification/NotificationModel';
import ValidatePermissions from '../users/ValidatePermissions';

const styles = () => ({
  root: {
    flexGrow: 1
  },
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 80
  }
});

@inject('entitlementState', 'entitlementStatus', 'notificationState')
@observer
class EntitlementContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    entitlementState: shapes.modelOf(Entitlement).isRequired,
    entitlementStatus: shapes.state.isRequired,
    match: PropTypes.object.isRequired,
    notificationState: shapes.modelOf(NotificationModel).isRequired
  };

  constructor(props) {
    super(props);
    this.saveAndPublish = this.saveAndPublish.bind(this);
  }

  componentDidMount() {
    const { entitlementState, match } = this.props;
    const bookID = match.params.id;
    if (bookID) {
      entitlementState.fetch(bookID);
    }
  }

  saveAndPublish(arg) {
    const { entitlementState, match, language, notificationState } = this.props;
    const payload = arg;
    const updatedList = [];
    let isValid = true;
    let validationMsg = '';
    let run = true;
    // let live = true;

    payload.data.forEach((parent) => {
      parent.rows.forEach((child) => {
        child.moduleList.forEach((obj) => {
          updatedList.push(obj);
        });
      });
    });

    // updatedList.forEach((item) => {
    //   if (item.scenarioID !== 7) {
    //     const moduelIDVal = item.moduleID !== null ? item.moduleID : '';
    //     if (moduelIDVal.trim().length > 0) {
    //       live = false;
    //     }
    //   }
    // });

    // if (live) {
    //   isValid = false;
    //   validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_EMPTY_SAVE') }.defaultMessage;
    // }

    updatedList.forEach((item) => {
      if (run && item.scenarioID !== 7) {
        const moduelIDVal = item.moduleID !== null ? item.moduleID : '';
        const productID = item.productID !== null ? item.productID : '';
        const url = item.url !== null ? item.url : '';

        if (moduelIDVal.trim().length === 0 && item.isActive === 'Y') {
          run = false;
          isValid = false;
          if (item.isPrimary === 'Y' || item.isPrimary === null) {
            if (item.scenarioID === 11) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_IS_PRIMARY_11') }.defaultMessage;
            } else if (item.scenarioID === 88) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_IS_PRIMARY_88') }.defaultMessage;
            } else if (item.scenarioID === 16 || item.scenarioID === 18 || item.scenarioID === 89) {
              validationMsg = `${
                { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_ISPRIMARY_1') }.defaultMessage
              } ${item.scenarioID}. ${
                { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_ISPRIMARY_2') }.defaultMessage
              }`;
            } else {
              validationMsg = `${
                { ...language.getText('entitle.VALIDATION_ERROR_MODULEID_IS_PRIMARY_1') }.defaultMessage
              } ${item.scenarioID}. ${
                { ...language.getText('entitle.VALIDATION_ERROR_MODULEID_IS_PRIMARY_2') }.defaultMessage
              }`;
            }
          } else if (item.scenarioID === 11) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_11') }.defaultMessage;
          } else if (item.scenarioID === 88) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_88') }.defaultMessage;
          } else if (item.scenarioID === 16 || item.scenarioID === 18 || item.scenarioID === 89) {
            validationMsg = `${
              { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_PRIMARY_1') }.defaultMessage
            } ${item.scenarioID}. ${
              { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_PRIMARY_2') }.defaultMessage
            }`;
          } else {
            validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_MODULEID_1') }.defaultMessage} ${
              item.scenarioID
            }. ${{ ...language.getText('entitle.VALIDATION_ERROR_MODULEID_2') }.defaultMessage}`;
          }
        }

        if (item.isPDFPlayer === 'Y' && entitlementState.isPDFSupported === 'N' && moduelIDVal.trim().length > 0) {
          run = false;
          isValid = false;
          validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_PDF_PLAYER') }.defaultMessage;
        }

        if (moduelIDVal.trim().length > 0 && item.isActive === 'N') {
          run = false;
          isValid = false;
          if (item.isPrimary === 'Y' || item.isPrimary === null) {
            if (item.scenarioID === 11) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_NON_PRIMARY_11') }.defaultMessage;
            } else if (item.scenarioID === 88) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_NON_PRIMARY_88') }.defaultMessage;
            } else if (item.scenarioID === 16 || item.scenarioID === 18 || item.scenarioID === 89) {
              validationMsg = `${
                { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_PRIMARY_1') }.defaultMessage
              } ${item.scenarioID}. ${
                { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_PRIMARY_2') }.defaultMessage
              }`;
            } else {
              validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_NON_PRIMARY_1') }.defaultMessage} ${
                item.scenarioID
              }. ${{ ...language.getText('entitle.VALIDATION_ERROR_NON_PRIMARY_2') }.defaultMessage}`;
            }
          } else if (item.scenarioID === 11) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_11') }.defaultMessage;
          } else if (item.scenarioID === 88) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_88') }.defaultMessage;
          } else if (item.scenarioID === 16 || item.scenarioID === 18 || item.scenarioID === 89) {
            validationMsg = `${
              { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_NON_PRIMARY_1') }.defaultMessage
            } ${item.scenarioID}. ${
              { ...language.getText('entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_NON_PRIMARY_2') }.defaultMessage
            }`;
          } else {
            validationMsg = `${
              { ...language.getText('entitle.VALIDATION_ERROR_MODULEID_NON_PRIMARY_1') }.defaultMessage
            } ${item.scenarioID}. ${
              { ...language.getText('entitle.VALIDATION_ERROR_MODULEID_NON_PRIMARY_2') }.defaultMessage
            }`;
          }
        }

        if (productID.length === 0 && item.purchaseType === 2) {
          run = false;
          isValid = false;
          if (item.scenarioID === 11) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_PRODUCTID_11') }.defaultMessage;
          } else if (item.scenarioID === 88) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_PRODUCTID_88') }.defaultMessage;
          } else {
            validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_PRODUCTID') }.defaultMessage} ${
              item.scenarioID
            }. `;
          }
        }

        if (url.length === 0 && item.purchaseType === 3) {
          run = false;
          isValid = false;
          if (item.scenarioID === 11) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_URL_11') }.defaultMessage;
          } else if (item.scenarioID === 88) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_URL_88') }.defaultMessage;
          } else {
            validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_URL') }.defaultMessage} ${
              item.scenarioID
            }. `;
          }
        }

        if ((productID.length > 0 && moduelIDVal.length === 0) || (url.length > 0 && moduelIDVal.length === 0)) {
          run = false;
          isValid = false;
          if (item.scenarioID === 11) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_EMPTY_MODULEID_11') }.defaultMessage;
          } else if (item.scenarioID === 88) {
            validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_EMPTY_MODULEID_88') }.defaultMessage;
          } else if (item.scenarioID === 16 || item.scenarioID === 18 || item.scenarioID === 89) {
            validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_EMPTY_RESOURCEID') }.defaultMessage} ${
              item.scenarioID
            }. `;
          } else {
            validationMsg = `${{ ...language.getText('entitle.VALIDATION_ERROR_EMPTY_MODULEID') }.defaultMessage} ${
              item.scenarioID
            }. `;
          }
        }

        if (entitlementState.isIpadSupported !== 'Y') {
          if ((item.isMobile === 'Y' || item.isAndroid === 'Y') && item.isActive === 'Y') {
            run = false;
            isValid = false;
            if (item.scenarioID === 1) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_IPAD_NOT_SUPPORTED_SMS') }.defaultMessage;
            } else if (item.scenarioID === 16) {
              validationMsg = { ...language.getText('entitle.VALIDATION_ERROR_IPAD_NOT_SUPPORTED_RUMBA') }
                .defaultMessage;
            }
          }
        }
      }
    });

    if (!isValid) {
      notificationState.set({
        open: true,
        type: 'error',
        message: validationMsg
      });
    } else {
      payload.bookID = match.params.id;
      payload.requestPayload = updatedList;
      entitlementState.saveAndPublish(payload);
      if (!payload.isPublish) {
        notificationState.set({
          open: true,
          type: 'success',
          message: { ...language.getText('entitle.PUBLISH_SUCCESS') }.defaultMessage
        });
      }
    }
  }

  render() {
    const { entitlementState, entitlementStatus, classes } = this.props;

    return (
      <ValidatePermissions givenPermissions="authoring_can_view" fallElse>
        <LoadingHandler
          loading={entitlementStatus.isPending}
          isError={entitlementStatus.isError && entitlementStatus.error && entitlementStatus.error.status === 401}
          loadingContent={<Loader />}
          errorContent={<StateErrorDisplay error={entitlementStatus.error} showDetails />}
          content={
            <>
              <Header />
              <main className={classes.contentWrapper} id="mainContent" role="main">
                <EntitlementHome
                  {...this.props}
                  moduleListState={entitlementState.bookModuleListConfig}
                  saveAndPublish={this.saveAndPublish}
                />
              </main>
            </>
          }
        />
      </ValidatePermissions>
    );
  }
}

export default withStyles(styles)(inject('language')(EntitlementContainer));
