/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { shapes } from '@greenville/framework';
import { inject } from 'mobx-react';
import { InputLabel, MenuItem, FormControl, Select, TextField, InputAdornment, IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import searchConfig from '../../../../common/config/books/SearchConfig';

const styles = () => ({
  container: {
    marginBottom: 20
  },
  formControl: {
    marginLeft: 20,
    marginRight: 20,
    minWidth: 120
  },
  filterControl: {
    minWidth: 120
  },
  filterBy: {
    width: '15ch'
  }
});

const BookSearch = ({ bookSearch, appliedFilter, language, classes }) => {
  const { name, value } = appliedFilter;
  const [filterby, setFilterBy] = React.useState(name || '');
  const [searchText, setsearchText] = React.useState(value || '');
  const [open, setOpen] = React.useState(false);

  const handleFilterChange = (filterValue) => {
    setFilterBy(filterValue);
  };

  const handleTextChange = (searchTextValue) => {
    // if (filterby === 'bookID') {
    //   const intValue = Number.isNaN(Number(searchTextValue)) ? '' : searchTextValue;
    //   setsearchText(intValue);
    // } else {
    setsearchText(searchTextValue);
    // }
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleClick = () => {
    let searchValues = {
      search: {
        [filterby]: searchText
      }
    };
    if (searchText === '') {
      handleFilterChange('');
      searchValues = {
        search: {
          isFavorite: true
        }
      };
    }
    const filter = {
      name: searchText === '' ? '' : filterby,
      value: searchText
    };
    bookSearch(searchValues, filter);
  };

  const handleOnKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.filterControl}>
        <InputLabel>
          <FormattedMessage {...language.getText('search.FILTER_BY')} />
        </InputLabel>
        <Select
          className={classes.filterBy}
          open={open}
          onClose={handleFilterClose}
          onOpen={handleFilterOpen}
          value={filterby}
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          {searchConfig.filterByValues.map((filter) => (
            <MenuItem value={filter.name}>{filter.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label={<FormattedMessage {...language.getText('search.ENTER_TEXT_HERE')} />}
          value={searchText}
          onKeyUp={handleOnKeyUp}
          onChange={(e) => handleTextChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton type="submit" onClick={handleClick} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </div>
  );
};

BookSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  bookSearch: PropTypes.func,
  appliedFilter: PropTypes.object
};

BookSearch.defaultProps = {
  bookSearch: '',
  appliedFilter: {}
};

export default withStyles(styles)(inject('language')(BookSearch));
