import React from 'react';
import ValidatePermissions from '../../users/ValidatePermissions';
import BulkIngestionComp from './BulkIngestionComp';

/* eslint-disable no-shadow */
function BulkView() {
  const showDashboardEnabled = false;
  return (
    <>
      <ValidatePermissions givenPermissions="bulk_can_view" showDashboardEnabled={showDashboardEnabled}>
        <BulkIngestionComp />
      </ValidatePermissions>
    </>
  );
}

BulkView.propTypes = {};

BulkView.defaultProps = {};

export default BulkView;
