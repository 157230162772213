/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

/**
 * Mobx State Tree Store
 */

const Children = types.model('Children', {
  children: types.maybeNull(types.array(types.late(() => Children))),
  href: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  urn: types.maybeNull(types.string),
  level: types.maybeNull(types.number)
});
// eslint-disable-next-line no-unused-vars
const TocData = types.model('TocData', {
  id1: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  children: types.optional(types.array(Children), [])
});

const Slates = types.model('Slates', {
  href: types.maybeNull(types.string),
  id: types.maybeNull(types.string),
  index: types.maybeNull(types.number),
  pagenumber: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  uri: types.maybeNull(types.string)
});

const layoutDimensions = types.model('layoutDimensions', {
  height: types.maybeNull(types.number),
  width: types.maybeNull(types.number),
  rightStart: types.maybeNull(types.boolean)
});

const preferenceObject = types.model('preferenceObject', {
  sliderVal: types.maybeNull(types.string),
  theme: types.maybeNull(types.string)
});

const EpubAsset = types
  .model('EpubAsset', {
    author: types.maybeNull(types.string),
    basePath: types.maybeNull(types.string),
    bookId: types.maybeNull(types.string),
    contentType: types.maybeNull(types.string),
    courseId: types.maybeNull(types.string),
    indexId: types.maybeNull(types.string),
    isRightToLeft: types.maybeNull(types.boolean),
    thumbnailUrl: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    title2: types.maybeNull(types.string),
    versionUrn: types.maybeNull(types.string),
    isDoublePage: types.maybeNull(types.boolean),
    isFixedLayout: types.maybeNull(types.boolean),
    layoutDimensions: types.maybeNull(layoutDimensions),
    preferenceObject: types.maybeNull(preferenceObject),
    tocData: types.maybeNull(TocData),
    slates: types.optional(types.array(Slates), [])
  })
  .actions((self) => ({
    fetch(id) {
      Framework.getEventManager().publish(constants.EPUB_TOC_DATA_FETCHED, { id });
    },
    set(response) {
      applySnapshot(self, response.data);
    }
  }));

export default EpubAsset;
