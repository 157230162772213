import { shapes } from '@greenville/framework';
import { makeStyles } from '@material-ui/core';
import { BerlinServices, ChatComponent } from '@vega/aitutor';
import axios from 'axios';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import * as constants from '../../../common/constants';
import env from '../../../common/env';
import utils from '../../../common/utils';
import chapterList from '../data/chapterList.json';
import GetPiTokenModel from '../models/GetPiTokenModel';

const useStyles = makeStyles({
  heading: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    top: '13%',
    fontSize: '18px',
    fontWeight: '600'
  }
});

const ServiceNow = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [tokenExpireTime, setTokenExpireTime] = useState(null);
  const [tokenValue, setTokenValue] = useState('');

  const getPiTokenApiCall = async () => {
    const headers = {
      Authorization: `Bearer ${tokenValue}`
    };
    const response = await axios.get(`${env.EVERGREEN_API_BASE_URL}${constants.GET_PI_TOKEN_URL}`, { headers });
    if (response && response.data && response.data.data) {
      const { token, expiresIn } = response.data.data;
      setUserToken(token);
      setTokenExpireTime(expiresIn);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setTokenValue(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tokenValue)) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 500);
      getPiTokenApiCall();
    }
  }, [tokenValue]);
  /* eslint-disable */

  useEffect(() => {
    if (!tokenExpireTime) return;
    const timeLeft = tokenExpireTime - Date.now() - 59000;
    const timeOutId = setTimeout(() => {
      getPiTokenApiCall();
    }, timeLeft);
    return () => clearTimeout(timeOutId);
  }, [tokenExpireTime]);

  /* eslint-disable */
  const token = userToken;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'tenant-id': 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed', // PROD 29bb65c6-12f8-4cca-9734-fb91899d1c4c
    'tenant-key': 'ff30d520-6137-46a2-b026-c5597793e3d8',
    'x-berlin-tenant-id': '7408ccc0-86ac-40e5-9d6d-9e1eae93900c', // PROD 29bb65c6-12f8-4cca-9734-fb91899d1c4c
    'x-berlin-tenant-key': 'f917690b-f60f-4f77-93c8-2ef8eacf5d3e' // PROD 0b64f2fe-1e38-4922-b87a-537b2a36be26
  };
  const streamHeader = {
    'new-ai-search-flow-enable': true,
    'enable-berlin-index': true,
    'tenant-id': 'bfff63e6-3f6b-4aa9-acd8-fab7a71ee8ed',
    'tenant-key': 'ff30d520-6137-46a2-b026-c5597793e3d8'
  };
  const streamBaseUrl = 'https://berlin-api-pplus.etext-dev.pearsondev.tech/api/2.0';
  let cancelTokenSource = axios.CancelToken.source();

  const getBerlinClientAPI = () => {
    cancelTokenSource = axios.CancelToken.source();
    // console.log('callback for getting berlin client');
    const berlinClient1 = new BerlinServices(
      axios.create({
        baseURL: 'https://berlin-api-pplus.etext-dev.pearsondev.tech/api/2.0',
        cancelToken: cancelTokenSource.token,
        timeout: 180000,
        headers
      })
    );
    return berlinClient1;
  };

  const getBerlinHistoryClientAPI = () => {
    cancelTokenSource = axios.CancelToken.source();
    // console.log('callback for getting berlin client');
    const berlinHistoryClient = new BerlinServices(
      axios.create({
        baseURL: 'https://berlin-api-pplus.etext-dev.pearsondev.tech/api/1.0',
        cancelToken: cancelTokenSource.token,
        timeout: 180000,
        headers
      })
    );
    return berlinHistoryClient;
  };

  //   const handleCancelRequest = () => {
  //     cancelTokenSource.cancel('Request was canceled');
  //   };

  const Users = {
    username: 'Vignesh Selvam',
    initials: 'VS',
    isFirstTimeAITutorUser: false
  };

  const getAuthToken = (callback) => {
    const tokenConfig = {
      id: 'token',
      token
    };
    return new Promise((resolve) => {
      resolve(callback(tokenConfig.token));
    });
  };

  return (
    <div>
      <div className={classes.heading}> Welcome to Peri </div>
      <ChatComponent
        isPageLoaded={isPageLoaded}
        users={Users}
        masterTheme="plus"
        theme="default"
        AIChatPlaceHolderText="Ask a question or describe what you need"
        context={{
          bookTitle: 'Campbell Biology, 12e',
          productModel: 'ETEXT_EPUB_BRONTE',
          indexId: '0fc5534dbbeaad9b8dbdbdaa9ff4a74f',
          pageId: 'f57c8f80-31ca-11ee-8109-7e1731ad9766',
          bookID: 'BRNT-XUF8BS197',
          secondaryBookId: 'd9096892-1923-4685-ab27-b6c32dd9455d',
          chapterList,
          chapterId: 'cfc05d6e0-31ca-11ee-8eb0-d709f5427093',
          chapterTitle: 'Chapter 4: Carbon and the Molecular Diversity of Life',
          chapterNumber: 19,
          contentType: 'PXE',
          enableFreeResponse: true,
          enableParagraph: false,
          tenantObj: {
            tenantId: '30258aed-46bb-4ec5-909e-ecea2431772b',
            tenantKey: '297a5917-24fa-43fe-a27b-426126bd7cf4'
          },
          berlinTenantObject: {
            tenantId: '7408ccc0-86ac-40e5-9d6d-9e1eae93900c', // PROD 29bb65c6-12f8-4cca-9734-fb91899d1c4c
            tenantKey: 'f917690b-f60f-4f77-93c8-2ef8eacf5d3e' // PROD 0b64f2fe-1e38-4922-b87a-537b2a36be26
          },
          currentSectionIds: [
            'a8920d6d45fc2049bb7a1ae473e02560346bd10bd-P70010169410000000000000000012CC',
            'a1df7a3cea32a09416bbc3c5a6259196709312d99'
          ]
        }}
        streamHeader={streamHeader}
        streamBaseUrl={streamBaseUrl}
        getBerlinClientAPI={getBerlinClientAPI}
        getBerlinHistoryClientAPI={getBerlinHistoryClientAPI}
        getAuthToken={getAuthToken}
        showChatBotIcon
        showUserHistory
        expandMode={false}
      />
    </div>
  );
};

ServiceNow.propTypes = {
  getPiToken: shapes.modelOf(GetPiTokenModel).isRequired
};

export default observer(inject('getPiToken')(ServiceNow));
