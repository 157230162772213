/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Paper, Box } from '@material-ui/core';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import BulkIngestion from '../models/BulkIngestion';
import Upload from '../../contentManager/models/Upload';
// import BulkIngestionConfig from '../../../common/config/BulkIngestionConfig';
import ChooseFile from '../../../common/components/ChooseFile';
import BulkIngestionTable from './BulkIngestionTable';
import BulkIngestionLog from '../models/BulkIngestionLog';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -40
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  }
});
@inject('bulkIngestion', 'upload', 'bulkIngestionLog')
@observer
class BulkIngestionComp extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    handleOpenUploadContent: PropTypes.func.isRequired,
    bulkIngestion: shapes.modelOf(BulkIngestion).isRequired,
    upload: shapes.modelOf(Upload).isRequired,
    bulkIngestionLog: shapes.modelOf(BulkIngestionLog).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      uploadContentPath: '',
      titleType: 'Spdf',
      IngestionLogs: {}
    };
    this.createContentPath = this.createContentPath.bind(this);
  }

  componentDidMount() {
    const { bulkIngestionLog } = this.props;
    bulkIngestionLog.fetchLogs();
    Framework.getEventManager().on(constants.SET_BULK_INGESTION_LOGS, () => {
      this.setState({ IngestionLogs: bulkIngestionLog.data });
    });
  }

  createContentPath = () => {
    const uploadContentPathUrl = `${constants.BULK_INGESTION_FOLDER}${constants.SEPARATOR}${utils.uuid()}${
      constants.SEPARATOR
    }`;
    this.setState({ uploadContentPath: uploadContentPathUrl });
  };

  handleClick = () => {
    const { bulkIngestion, upload } = this.props;
    const { uploadContentPath, titleType } = this.state;
    const result = upload.bucketFolders;
    const sheetName = result[0];
    const finalPath = `${uploadContentPath}${sheetName}`;
    bulkIngestion.fetchPath(finalPath, titleType);
  };

  render() {
    const { classes, language } = this.props;
    const { uploadContentPath, IngestionLogs } = this.state;
    if (!uploadContentPath) {
      this.createContentPath();
    }
    return (
      <>
        <div className={classes.container}>
          <Paper className={classes.paper} elevation={3}>
            <Grid className={classes.title} container direction="column" alignItems="center" justify="space-between">
              <ChooseFile
                message={{ ...language.getText('bulkutility.UPLOAD_EXCEL') }.defaultMessage}
                uploadPath={uploadContentPath}
              />
              <Grid item className={classes.button}>
                <Button variant="outlined" color="primary" size="medium" onClick={() => this.handleClick()}>
                  <FormattedMessage {...language.getText('Start Processing')} />
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Paper variant="outlined">
            <Box m={1}>
              <BulkIngestionTable data={IngestionLogs} />
            </Box>
          </Paper>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(observer(inject('language', 'bulkIngestionLog')(BulkIngestionComp)));
