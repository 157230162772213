/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const logs = types.model('logs', {
  bulk_ingestion_uuid: types.maybeNull(types.string),
  content_name: types.maybeNull(types.string),
  content_path: types.maybeNull(types.string),
  workflow: types.maybeNull(types.string),
  total_records: types.maybeNull(types.number),
  failed_records: types.maybeNull(types.number),
  action_user: types.maybeNull(types.string),
  uploaded_date: types.maybeNull(types.string),
  completed_date: types.maybeNull(types.string),
  status: types.maybeNull(types.number)
});

const BulkIngestionLog = types
  .model('BulkIngestionLog', {
    data: types.optional(types.array(logs), [])
  })
  .actions((self) => ({
    fetchLogs() {
      Framework.getEventManager().publish(constants.GET_BULK_INGESTION_LOGS);
    },
    setIngestionLogs(response) {
      applySnapshot(self.data, response);
      Framework.getEventManager().publish(constants.SET_BULK_INGESTION_LOGS);
    },
    start(payload) {
      Framework.getEventManager().publish(constants.START_MIGRATION, { payload });
    }
  }));

export default BulkIngestionLog;
