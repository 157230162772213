const entitlementList = [
  {
    title: 'SMS Student/Instructor Connection',
    color: '#9f1800',
    description: '(All allow for private and shared notes and highlights and nav baskets)',
    headers: [
      '',
      'Integration Scenario',
      'Site ID',
      'PDF Player',
      'Module ID',
      'Live',
      'Product ID',
      'URL',
      'Type',
      'Primary',
      ''
    ],
    rows: [
      {
        scenarioID: 1,
        scenarioLabel: 1,
        title: 'SMS Full Access',
        description: 'Course creation handled by Pearson eText',
        moduleList: []
      },
      {
        scenarioID: 3,
        scenarioLabel: 3,
        title: 'SMS API',
        description: 'Course created in parent platform',
        moduleList: []
      },
      {
        scenarioID: 11,
        scenarioLabel: 11,
        title: 'SMS LTI',
        description: 'Course created in MathXL',
        moduleList: []
      }
    ]
  },
  {
    title: 'SMS No Student/Instructor Connection',
    color: '#9f1800',
    description: '',
    headers: [
      '',
      'Integration Scenario',
      'Site ID',
      'PDF Player',
      'Module ID',
      'Live',
      'Product ID',
      'URL',
      'Type',
      'Primary',
      ''
    ],
    rows: [
      {
        scenarioID: 6,
        scenarioLabel: 6,
        title: 'SMS Disaggregated',
        description: 'Private notes and highlights, no nav baskets',
        moduleList: []
      },
      {
        scenarioID: 88,
        scenarioLabel: 11,
        title: 'SMS LTI Disaggregated',
        description: 'No nav baskets',
        moduleList: []
      }
    ]
  },
  // {
  //   title: 'PSN / PSN+ / Trusted Platform',
  //   description: '',
  //   headers: ['', 'Integration Scenario', 'Site ID', 'PDF Player', 'S7_BookID', 'Live', '', '', '', '', ''],
  //   rows: [
  //     {
  //       scenarioID: 7,
  //       scenarioLabel: 7,
  //       title: 'Third Party API',
  //       description: 'Course created in parent platform',
  //       moduleList: []
  //     }
  //   ]
  // },
  {
    title: 'RUMBA',
    color: '#00bcd4',
    description: '',
    headers: [
      '',
      'Integration Scenario',
      ' Context ID',
      'PDF Player',
      'Resource ID',
      'Live',
      'Product ID',
      'URL',
      'Type',
      'Primary',
      ''
    ],
    rows: [
      {
        scenarioID: 16,
        scenarioLabel: 16,
        title: 'RUMBA Full Access',
        description: 'RUMBA universal login page, bookshelf and access using deeplinks',
        moduleList: []
      },
      {
        scenarioID: 18,
        scenarioLabel: 18,
        title: 'RUMBA LTI',
        description: 'Full access of eText and course created in parent platform',
        moduleList: []
      },
      {
        scenarioID: 89,
        scenarioLabel: 18,
        title: 'RUMBA LTI Disaggregated',
        description: 'Disaggregated Access of eText, course created in parent platform',
        moduleList: []
      }
    ]
  },
  {
    title: 'Digital Library',
    color: '#0fb353',
    description: '',
    headers: ['', '', 'Site ID', 'PDF Player', 'Module ID', 'Live', '', '', '', 'Primary', ''],
    rows: [
      {
        scenarioID: 17,
        scenarioLabel: 17,
        title: 'SMS Digital Library',
        description: '',
        moduleList: []
      },
      {
        scenarioID: 19,
        scenarioLabel: 19,
        title: 'RUMBA Digital Library',
        description: '',
        moduleList: []
      }
    ]
  }
  // Code added but not yet used for Bronte Epub Publish
  // {
  //   title: 'Pearson Places',
  //   color: '#00abd4',
  //   description: '',
  //   headers: [
  //     '',
  //     'Integration Scenario',
  //     ' Context ID',
  //     'PDF Player',
  //     'Resource ID',
  //     'Live',
  //     'Product ID',
  //     'URL',
  //     'Type',
  //     'Primary',
  //     ''
  //   ],
  //   rows: [
  //     {
  //       scenarioID: 2,
  //       scenarioLabel: 2,
  //       title: 'Pearson Places Full Access',
  //       description: '',
  //       moduleList: []
  //     },
  //     {
  //       scenarioID: 18,
  //       scenarioLabel: 18,
  //       title: 'Pearson Places EPUB',
  //       description: '',
  //       moduleList: []
  //     }
  //   ]
  // }
];

const entitlementConfig = {
  entitlementList
};

module.exports = entitlementConfig;
