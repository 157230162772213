/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const BulkIngestion = types
  .model('BulkIngestion', {
    BulkIngestionPath: types.maybeNull(types.string),
    titleType: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fetchPath(path, type) {
      Framework.getEventManager().publish(constants.BULK_INGESTION, { path, type });
    },
    set(response) {
      applySnapshot(self.BulkIngestionPath, response.data);
    }
  }));

export default BulkIngestion;
