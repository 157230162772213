/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
import { BookMetadata } from './BookMetadata';

/**
 * Mobx State Tree Store
 */
const BookFavorite = types.model('BookFavorite', {
  userID: types.maybeNull(types.integer),
  isFavorite: types.maybeNull(types.string),
  editionID: types.maybeNull(types.integer)
});

const BookHistory = types.model('BookHistory', {
  updatedDate: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  status: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  titleType: types.maybeNull(types.string),
  pulseBookID: types.maybeNull(types.string),
  pulseProductID: types.maybeNull(types.string)
});

const IsbnList = types.model('IsbnList', {
  displayISBN: types.maybeNull(types.string),
  isbnTypeID: types.maybeNull(types.integer),
  type: types.maybeNull(types.string)
});

const pulseData = types.model('pulseData', {
  pulseBookID: types.maybeNull(types.string),
  pulseProductID: types.maybeNull(types.string)
});

const AuthorDetail = types.model('AuthorDetail', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  otherName: types.maybeNull(types.string),
  authorID: types.maybeNull(types.integer)
});

const BookDetails = types.model('BookDetails', {
  globalBookID: types.maybeNull(types.string),
  bookID: types.maybeNull(types.integer),
  title: types.maybeNull(types.string),
  thumbnail: types.maybeNull(types.string),
  bookEditionID: types.maybeNull(types.integer),
  editionTypeID: types.maybeNull(types.integer),
  bookEditionNumber: types.maybeNull(types.string),
  isbnList: types.optional(types.array(IsbnList), []),
  pageCount: types.maybeNull(types.integer),
  successPageStatusCount: types.maybeNull(types.integer),
  lastModified: types.maybeNull(types.string),
  modifiedBy: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  isFavorite: types.maybeNull(types.string),
  authorList: types.optional(types.array(AuthorDetail), []),
  dlCompatible: types.maybeNull(types.string),
  ipadCompatible: types.maybeNull(types.string),
  isServerSideEnabled: types.maybeNull(types.string),
  isDLSupported: types.maybeNull(types.string),
  isS3Uploaded: types.maybeNull(types.string),
  isPdfPlayerSupported: types.maybeNull(types.string),
  serverSideProcessLogStatus: types.maybeNull(types.integer),
  serverSideProcessStatus: types.maybeNull(types.integer),
  titleType: types.maybeNull(types.string),
  pulseBookID: types.maybeNull(types.string),
  pulseProductID: types.maybeNull(types.string),
  pulseDetails: types.optional(types.array(pulseData), [])
});

const Books = types
  .model('Books', {
    bookList: types.optional(types.array(BookDetails), []),
    bookDetail: types.compose(BookDetails),
    bookFavorite: types.compose(BookFavorite),
    bookHistory: types.optional(types.array(BookHistory), []),
    bookMetadata: types.compose(BookMetadata)
  })
  .actions((self) => ({
    /* eslint-disable no-param-reassign */
    fetch(data) {
      Framework.getEventManager().publish(constants.BOOK_SEARCH_DATA_FETCHED, data);
    },
    fetchBook(id, useCallback = false) {
      Framework.getEventManager().publish(constants.BOOK_SEARCH_DATA_FETCHED, { id, useCallback });
    },
    fetchBookMetadata(bookID) {
      Framework.getEventManager().publish(constants.BOOK_METADATA_FETCHED, { bookID });
    },
    // fetchBookMetadata(bookID) {
    //   Framework.getEventManager().publish(constants.BOOK_METADATA_FETCHED, { bookID });
    // },
    updateBookFavorite(favorite, window) {
      Framework.getEventManager().publish(constants.BOOK_SEARCH_DATA_FETCHED, { favorite, window });
    },
    fetchBookHistory(bookID) {
      Framework.getEventManager().publish(constants.BOOK_SEARCH_DATA_FETCHED, { bookID, model: 'BOOK_HISTORY' });
    },
    set(dashboard) {
      applySnapshot(self.bookList, dashboard.data);
    },
    setBook(bookResponse, useCallback) {
      if (useCallback) {
        applySnapshot(self.bookDetail, bookResponse.data);
        Framework.getEventManager().publish(constants.BOOK_USECALLBACK);
      }
      applySnapshot(self.bookDetail, bookResponse.data);
    },
    setFavoriteDetail(favoriteResponse) {
      const updatedState = self;
      updatedState.bookDetail.isFavorite = favoriteResponse.data.isFavorite;
      applySnapshot(self, updatedState);
    },
    setFavoriteList(favoriteResponse) {
      const updatedState = self;
      updatedState.bookList.forEach((child) => {
        if (child.bookEditionID === favoriteResponse.data.bookEditionID) {
          child.isFavorite = favoriteResponse.data.isFavorite;
        }
      });
      applySnapshot(self, updatedState);
    },
    setBookHistory(bookHistoryResponse) {
      applySnapshot(self.bookHistory, bookHistoryResponse.data);
    },
    setBookMetadata(bookMetadataResponse) {
      if (bookMetadataResponse) {
        applySnapshot(self.bookMetadata, bookMetadataResponse);
      }
    }
    /* eslint-enable no-param-reassign */
  }));

export default Books;
