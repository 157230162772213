/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2023 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import Framework, { BaseService } from '@greenville/framework';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';

export default class GetPulseAssetService extends BaseService {
  constructor() {
    super(constants.GET_PULSE_ASSETS, 'bulkAssetStatus');
  }

  handleEvent() {
    return this.getClient('evergreen').get('bulkutility/pulseassets');
  }

  handleResponse(response) {
    // eslint-disable-line class-methods-use-this
    const { data } = response;
    if (data && data.length > 0) {
      const notification = {
        open: true,
        message: 'Data Fetched Successfully',
        type: 'info'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
      Framework.getStoreRegistry().getStore('bulkAssets').setAssets(response.data);
    }
  }

  handleError(error) {
    // eslint-disable-line class-methods-use-this
    const { response } = error;
    if (response) {
      const { status, data } = response;
      if (status === 401) {
        utils.redirectToLogin();
      }
      if ((status === 500 || status === 400) && data && data.error) {
        const notification = {
          open: true,
          message: data.error.message,
          type: 'error'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  }
}
