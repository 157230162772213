import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { PropTypes } from 'mobx-react';
import React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const DialogBox = ({
  open,
  handleClose,
  handleConfirm,
  title,
  handleTextFieldHandler,
  itemName,
  label,
  isFromCustomAssessment
}) => {
  const textFieldHandler = (e) => {
    handleTextFieldHandler(e);
  };
  const handleConfirmation = async (e, confirmed) => {
    if (itemName) {
      await handleConfirm(confirmed);
    }
    handleClose(e);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{ style: { width: '80%' } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers style={{ textAlign: 'center' }}>
        <TextField
          id="comments_id"
          label={label}
          multiline
          fullWidth
          rows={2}
          variant="outlined"
          value={itemName}
          onChange={(e) => textFieldHandler(e)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" size="small" onClick={(e) => handleConfirmation(e, false)}>
          Cancel
        </Button>
        <Button
          onClick={(e) => handleConfirmation(e, true)}
          color="primary"
          variant="outlined"
          size="small"
          disabled={itemName.length === 0}
        >
          {isFromCustomAssessment ? 'Create' : 'Confirm'}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

DialogBox.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  handleConfirm: PropTypes.func,
  title: PropTypes.string,
  // content: PropTypes.string,
  handleTextFieldHandler: PropTypes.func,
  itemName: PropTypes.string,
  label: PropTypes.string,
  isFromCustomAssessment: PropTypes.bool
};

DialogBox.defaultProps = {
  open: true,
  title: null,
  handleConfirm: () => {},
  handleClose: () => {},
  // content: '',
  handleTextFieldHandler: () => {},
  itemName: '',
  label: 'Add Your Comments Here',
  isFromCustomAssessment: false
};

export default DialogBox;
