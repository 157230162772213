const bulkIngestionType = [
  {
    id: 0,
    value: 'Epub'
  },
  {
    id: 1,
    value: 'Spdf'
  }
];

const status = [
  {
    id: 0,
    value: 'Not Started'
  },
  {
    id: 1,
    value: 'In Progress'
  },
  {
    id: 2,
    value: 'Success'
  },
  {
    id: 3,
    value: 'Failed'
  }
];

const region = [
  {
    id: 0,
    value: 'IRE'
  },
  {
    id: 1,
    value: 'SIN'
  }
];

const BulkIngestionConfig = {
  bulkIngestionType,
  status,
  region
};

module.exports = BulkIngestionConfig;
