/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { types, applySnapshot } from 'mobx-state-tree';
import { toJS } from 'mobx';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';
// import { AuthorDetail, IsbnList } from './Books';

export const BookLevel = types.model('BookLevel', {
  levelTypeID: types.maybeNull(types.integer),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string)
});

const IsbnList = types.model('IsbnList', {
  displayISBN: types.maybeNull(types.string),
  isbnTypeID: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

const AuthorDetail = types.model('AuthorDetail', {
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  otherName: types.maybeNull(types.string),
  authorID: types.maybeNull(types.string)
});

export const CoverArt = types.model('CoverArt', {
  thumbnailArt: types.maybeNull(types.string),
  thumbnailArtFormatID: types.maybeNull(types.string),
  thumbnailDpi: types.maybeNull(types.integer),
  coverArt: types.maybeNull(types.string),
  coverArtFormatID: types.maybeNull(types.string),
  coverDpi: types.maybeNull(types.string),
  navigationArt: types.maybeNull(types.string),
  navigationArtFormatID: types.maybeNull(types.string),
  navigationDpi: types.maybeNull(types.string),
  pdfCoverArt: types.maybeNull(types.string),
  pdfCoverFormatID: types.maybeNull(types.string),
  pdfCoverDpi: types.maybeNull(types.string)
});

export const BookEdition = types.model('BookEdition', {
  bookEditionNumber: types.maybeNull(types.string),
  bookEditionID: types.maybeNull(types.string),
  bookEditionTypeID: types.maybeNull(types.string)
});

export const BookMetadata = types
  .model('BookMetadata', {
    // repeated from book details
    bookID: types.maybeNull(types.integer),
    globalBookID: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    pulseBookID: types.maybeNull(types.string),
    pulseProductID: types.maybeNull(types.string),
    titleType: types.maybeNull(types.string),

    // should be part of book data
    copyrightYear: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    hasTocFlatten: types.maybeNull(types.string),
    minimumHotspotHeight: types.maybeNull(types.string),
    orientation: types.maybeNull(types.string),

    version: types.maybeNull(types.string),
    numberOfLevels: types.maybeNull(types.string),
    numberOfDisplayLevels: types.maybeNull(types.string),
    bookEditionNumber: types.maybeNull(types.string),
    bookEditionID: types.maybeNull(types.string),
    bookEditionTypeID: types.maybeNull(types.string),

    authorList: types.optional(types.array(AuthorDetail), []),
    isbnList: types.optional(types.array(IsbnList), []),
    bookLevels: types.optional(types.array(BookLevel), []),

    thumbnailArt: types.maybeNull(types.string),
    thumbnailArtFormatID: types.maybeNull(types.string),
    thumbnailDpi: types.maybeNull(types.string),
    coverArt: types.maybeNull(types.string),
    coverArtFormatID: types.maybeNull(types.string),
    coverDpi: types.maybeNull(types.string),
    navigationArt: types.maybeNull(types.string),
    navigationArtFormatID: types.maybeNull(types.string),
    navigationDpi: types.maybeNull(types.string),
    pdfCoverArt: types.maybeNull(types.string),
    pdfCoverFormatID: types.maybeNull(types.string),
    pdfCoverDpi: types.maybeNull(types.string)
  })
  .actions((self) => ({
    addauthorsRow(item) {
      const newAuthors = toJS(item);
      const updatedAuthorsList = toJS(self);
      newAuthors.firstName = '';
      newAuthors.lastName = '';
      newAuthors.otherName = '';

      updatedAuthorsList.authorList.push({ firstName: '', lastName: '', otherName: '' });
      applySnapshot(self, updatedAuthorsList);
    },
    removeauthorsRow(i) {
      // item = toJS(item);
      const updatedAuthorsList = toJS(self);
      updatedAuthorsList.authorList.splice(i, 1);
      applySnapshot(self, updatedAuthorsList);
    },
    editMetadata(data, type) {
      const updatedSelf = toJS(self);
      updatedSelf[type] = data;
      applySnapshot(self[type], data);
    },
    savePayload(payload) {
      Framework.getEventManager().publish(constants.BOOK_METADATA_POST, { payload });
    }
  }));
