/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject } from 'mobx-react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
// import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';
// import Framework from '@greenville/framework';

// import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const styles = () => ({
  posClass: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
      color: '#005d83',
      textDecoration: 'underline',
      textDecorationColor: '#005d83'
    }
  }
});

class Breadcrumb extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    path: PropTypes.object.isRequired,
    sheetPath: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    identifierName: PropTypes.string.isRequired,
    handleBreadcrumb: PropTypes.func.isRequired,
    handleUpload: PropTypes.bool.isRequired
  };

  getPath = (folderName) => {
    const { path, sheetPath, handleBreadcrumb } = this.props;
    if (path) {
      const folderPath = path.split(folderName);
      folderPath.pop();
      handleBreadcrumb(`${folderPath}${folderName}${'/'}`);
    } else {
      const folderPath = sheetPath;
      handleBreadcrumb(`${folderPath}`);
    }
  };

  render() {
    const { classes, path, handleUpload, uuid, sheetPath, identifierName } = this.props;
    let breadcrumbPath = null;
    if (path) {
      breadcrumbPath = path.split('/');
      if (_.includes(breadcrumbPath, 'ingest')) {
        const ingestFolderIndex = _.indexOf(breadcrumbPath, 'ingest');
        breadcrumbPath.splice(0, ingestFolderIndex - 1);
      } else {
        const identifierIndex = _.indexOf(breadcrumbPath, identifierName);
        breadcrumbPath.splice(0, identifierIndex);
      }
    }
    if (sheetPath) {
      breadcrumbPath = uuid;
    }
    return (
      <Breadcrumbs aria-label="breadcrumb">
        {path ? (
          breadcrumbPath.map((pathValue) =>
            pathValue ? (
              <Typography
                key={pathValue}
                onClick={!handleUpload ? () => this.getPath(pathValue) : ''}
                color="textPrimary"
                className={!handleUpload ? classes.posClass : ''}
              >
                {pathValue}
              </Typography>
            ) : (
              ''
            )
          )
        ) : (
          <Typography
            key={breadcrumbPath}
            onClick={!handleUpload ? () => this.getPath(breadcrumbPath) : ''}
            color="textPrimary"
            className={!handleUpload ? classes.posClass : ''}
          >
            {breadcrumbPath}
          </Typography>
        )}
      </Breadcrumbs>
    );
  }
}

export default withStyles(styles)(inject('language')(Breadcrumb));
