/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';

import ServiceNowContainer from './ServiceNowContainer';
import GetPiTokenService from './service/GetPiTokenService';
import GetPiTokenModel from './models/GetPiTokenModel';

export default {
  path: ['/servicenow'],
  exact: true,
  services: [new GetPiTokenService()],
  stores: [
    {
      name: 'getPiToken',
      spec: GetPiTokenModel,
      saveLocally: false,
      initialState: {}
    }
  ],

  render: (props) => (
    <ServiceNowContainer {...props} /> // eslint-disable-line react/prop-types
  )
};
