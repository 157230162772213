/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { inject, observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField, Select, Box, FormControl, MenuItem, InputLabel, Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import Loader from '../../../../common/Loader';
import NoDataFound from '../../../../common/components/NoDataFound';
import SearchResultTable from './SearchResultTable';
import HistoryDialog from './HistoryDialog';
import Preview from './Preview';
import Workflow from '../../models/Workflow';
import CustomConfig from '../../../../common/config/CustomConfig';
import eTextConfig from '../../../../common/config/eTextConfig';
import * as constants from '../../../../common/constants';

const styles = () => ({
  root: {
    width: '100%'
  },
  formControl: {
    minWidth: 208
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5px'
  }
});

@inject('workflow')
@observer
// eslint-disable-next-line react/prefer-stateless-function
class WorkflowStatus extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    workflow: shapes.modelOf(Workflow).isRequired,
    language: shapes.language.isRequired,
    workflowSearchStatus: shapes.state.isRequired,
    workflowHistoryStatus: shapes.state.isRequired,
    cancelIngestionStatus: shapes.state.isRequired,
    radioTitleDetail: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    // const { workflow } = this.props;
    this.defaultSearch = {
      workflow: 'all',
      businessUnit: 'all',
      jobs: 'my',
      identifier: '',
      title: '',
      authors: '',
      globalBookId: '',
      bookId: ''
    };
    this.state = {
      workflowFields: {
        workflow: 'all',
        businessUnit: 'all',
        jobs: 'my',
        identifier: '',
        title: '',
        authors: '',
        globalBookId: '',
        bookId: '',
        totalCount: '',
        pageNumber: 1,
        bulkUUID: ''
      },
      open: false,
      rowData: {},
      titleData: {},
      openTitlePreview: false
    };

    this.handleViewHistory = this.handleViewHistory.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleCancelIngestion = this.handleCancelIngestion.bind(this);
  }

  componentDidMount() {
    const { workflow, workflowSearchStatus } = this.props;
    workflow.fetch();
    workflow.fetchDBFlag();
    const payload = {
      data: this.defaultSearch
    };
    workflow.savePayload(payload);
    Framework.getEventManager().on(constants.CM_WORKFLOW_SEARCH, () => {
      if (workflowSearchStatus.isError || workflow.workflowPostData.results.length === 0) {
        this.handleRadioChange({});
      }
    });
  }

  handleChange = (i, data, e) => {
    const { workflowFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      workflowFields: { ...workflowFields, ...fieldChanged }
    });
  };

  reset = () => {
    this.setState({
      workflowFields: this.defaultSearch
    });
  };

  handleSearch = () => {
    const { workflowSearchStatus } = this.props;
    const { workflowFields } = this.state;
    const request = {
      workflow: workflowFields.workflow,
      businessUnit: workflowFields.businessUnit,
      jobs: workflowFields.jobs,
      ...(workflowFields.identifier.length > 0 && {
        identifier: workflowFields.identifier
      }),
      ...(workflowFields.title.length > 0 && {
        title: workflowFields.title
      }),
      ...(workflowFields.authors.length > 0 && {
        authors: workflowFields.authors
      }),
      ...(workflowFields.globalBookId.length > 0 && {
        globalBookId: workflowFields.globalBookId
      }),
      ...(workflowFields.bookId.length > 0 && {
        bookId: workflowFields.bookId
      }),
      ...(workflowFields.bulkUUID.length > 0 && {
        bulkUUID: workflowFields.bulkUUID
      })
    };

    const payload = {
      data: request
    };

    const { workflow } = this.props;
    workflowFields.pageNumber = 1;
    workflow.savePayload(payload);
    Framework.getEventManager().on(constants.CM_WORKFLOW_SEARCH, () => {
      if (workflowSearchStatus.isError || workflow.workflowPostData.results.length === 0) {
        this.handleRadioChange({});
      }
    });
  };

  handleClose = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  handleRadioChange = (rowData) => {
    const { radioTitleDetail } = this.props;
    let object = {};
    if (rowData.identifier) {
      const businessUnitDetail = eTextConfig.businessUnit.find(
        (x) => x.value.toLowerCase() === rowData.businessUnit.toLowerCase()
      );
      object = {
        ...rowData,
        businessUnitId: businessUnitDetail.id
      };
      radioTitleDetail(object);
    } else {
      radioTitleDetail(object);
    }
  };

  handleViewHistory = (row) => {
    const { workflow } = this.props;
    workflow.fetchHistory(row);
    const { open } = this.state;
    this.setState({
      open: !open,
      rowData: row
    });
  };

  handleCancelIngestion = (row) => {
    const { workflow } = this.props;
    const { workflowFields } = this.state;
    const cancelPayload = {
      globalBookId: row.globalBookId,
      version: row.version,
      actionUser: row.actionUser,
      bookId: row.bookId,
      edition: row.edition
    };
    workflow.fetchCancelIngestionData(cancelPayload);
    Framework.getEventManager().on(constants.CM_INGESTION_CANCEL, () => {
      const payload = {
        data: workflowFields
      };
      workflow.savePayload(payload);
      this.setState({
        workflowFields: payload.data
      });
    });
  };

  handlePreview = (row, bookValue) => {
    const { workflow } = this.props;
    workflow.fetchPreviewData(row, bookValue);
    const { openTitlePreview } = this.state;
    this.setState({
      openTitlePreview: !openTitlePreview,
      rowData: row
    });
  };

  handlePreviewClose = () => {
    const { openTitlePreview } = this.state;
    this.setState({
      openTitlePreview: !openTitlePreview
    });
  };

  handleChangePage = (e, pageNumber) => {
    const { workflowFields } = this.state;
    const { workflow } = this.props;
    workflowFields.pageNumber = Number(pageNumber);
    workflowFields.totalCount = workflow.workflowPostData.totalCount;
    const payload = {
      data: workflowFields
    };
    workflow.savePayload(payload);
    this.setState({
      workflowFields: payload.data
    });
  };

  render() {
    const { classes, language, workflow, workflowSearchStatus, workflowHistoryStatus } = this.props;
    const { workflowFields, open, rowData, titleData, openTitlePreview } = this.state;

    const pageCount =
      (workflow.workflowPostData.totalCount / 8).toFixed() > 0
        ? (workflow.workflowPostData.totalCount / 8).toFixed()
        : 1;

    return (
      <LoadingHandler
        loading={workflowSearchStatus.isPending}
        isError={
          workflowSearchStatus.isError && workflowSearchStatus.error && workflowSearchStatus.error.status === 401
        }
        errorContent={<StateErrorDisplay error={workflowSearchStatus.error} showDetails />}
        content={
          <>
            <Container disableGutters>
              <br />
              <br />
              <Typography align="center" variant="h2">
                <FormattedMessage {...language.getText('contentmanager.WORKFLOW')} />
              </Typography>
              <Paper elevation={3}>
                <Box my={2} p={2} pb={3}>
                  <Grid container direction="row" spacing={3}>
                    <Grid container item xs={4} direction="column">
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.IDENTIFIER')} />}
                        margin="dense"
                        name="identifier"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.identifier}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.TITLE')} />}
                        margin="dense"
                        name="title"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.title}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.AUTHORS')} />}
                        margin="dense"
                        name="authors"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.authors}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid container item xs={4} direction="column" justify="center">
                      <FormControl className={classes.formControl}>
                        <InputLabel>
                          <FormattedMessage {...language.getText('contentmanager.WORK_FLOW')} />
                        </InputLabel>
                        <Select
                          name="workflow"
                          onChange={($event) => this.handleChange(null, null, $event)}
                          value={workflowFields.workflow}
                        >
                          {CustomConfig.contentWorkflow.map((filter) => (
                            <MenuItem value={filter.id}>{filter.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel>
                          <FormattedMessage {...language.getText('contentmanager.BUSINESS_UNIT')} />
                        </InputLabel>
                        <Select
                          name="businessUnit"
                          onChange={($event) => this.handleChange(null, null, $event)}
                          value={workflowFields.businessUnit}
                        >
                          <MenuItem value="all">All</MenuItem>
                          {workflow.workflowData.map((filter) => (
                            <MenuItem value={filter.businessUnitName}>{filter.businessUnitName}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <InputLabel>
                          <FormattedMessage {...language.getText('contentmanager.JOBS')} />
                        </InputLabel>
                        <Select
                          name="jobs"
                          onChange={($event) => this.handleChange(null, null, $event)}
                          value={workflowFields.jobs}
                        >
                          {CustomConfig.contentShowData.map((filter) => (
                            <MenuItem value={filter.id}>{filter.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid container item xs={4} direction="column">
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.GLOBAL_BOOK_ID')} />}
                        margin="dense"
                        name="globalBookId"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.globalBookId}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('contentmanager.BOOK_ID')} />}
                        margin="dense"
                        name="bookId"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.bookId}
                        variant="outlined"
                      />
                      <TextField
                        label={<FormattedMessage {...language.getText('bulkutility.UUID')} />}
                        margin="dense"
                        name="bulkUUID"
                        onChange={($event) => this.handleChange(null, null, $event)}
                        value={workflowFields.bulkUUID}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid container justify="space-evenly">
                      <Button variant="outlined" size="small" color="primary" onClick={this.handleSearch}>
                        <FormattedMessage {...language.getText('common.SEARCH')} />
                      </Button>
                      <Button variant="outlined" size="small" color="primary" onClick={this.reset}>
                        <FormattedMessage {...language.getText('common.RESET')} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              {workflowSearchStatus.isError || workflow.workflowPostData.results.length === 0 ? (
                <NoDataFound isError={workflowSearchStatus.isError} />
              ) : (
                <Paper variant="outlined">
                  <Box m={1}>
                    <Box m={1}>
                      <Typography>
                        {' '}
                        <FormattedMessage {...language.getText('label.TOTAL_COUNT')} />
                        {workflow.workflowPostData.totalCount}
                      </Typography>
                    </Box>
                    <SearchResultTable
                      data={workflow.workflowPostData.results}
                      DBFlag={workflow.fetchDbFlag.PEARSON_P2}
                      handleViewHistory={this.handleViewHistory}
                      handleTitlePreview={this.handlePreview}
                      titleData={titleData}
                      handleRadioChange={this.handleRadioChange}
                      handleCancelIngestion={this.handleCancelIngestion}
                    />
                    <Pagination
                      className={classes.pagination}
                      component="div"
                      color="primary"
                      count={pageCount}
                      defaultPage={workflowFields.pageNumber}
                      onChange={(e, page) => this.handleChangePage(e, page)}
                      showFirstButton
                      showLastButton
                    />
                  </Box>
                </Paper>
              )}
            </Container>
            <HistoryDialog
              openhistory={open}
              close={() => this.handleClose}
              rowData={rowData}
              workflowHistory={workflow.workflowHistoryData}
              workflowHistoryStatus={workflowHistoryStatus}
            />
            {openTitlePreview && (
              <Preview
                openTitlePreview={openTitlePreview}
                handlePreviewClose={() => this.handlePreviewClose}
                rowData={rowData}
              />
            )}
          </>
        }
        loadingContent={<Loader />}
      />
    );
  }
}

export default withStyles(styles)(observer(inject('language')(WorkflowStatus)));
