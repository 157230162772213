/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Framework, { shapes, LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import FolderIcon from '@material-ui/icons/Folder';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import withWindowDimensions from '../common/withWindowDimensions';
import env from '../../../../common/env';
import Upload from '../../models/Upload';
import Loader from '../../../../common/Loader';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import Breadcrumb from '../common/Breadcrumb';
import ConfirmationDialog from '../../../../common/components/ConfirmationDialog/Confirmation';
import * as constants from '../../../../common/constants';
import utils from '../../../../common/utils';

const styles = (theme) => ({
  tableRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6),
      textAlign: 'center'
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonSmall: {
    padding: '4px 10px',
    fontSize: 13,
    '& .MuiButton-label': {
      minWidth: 40
    }
  },
  dropzone: {
    cursor: 'pointer',
    backgroundColor: '#fafafa',
    border: '3px dashed #eeeeee',
    color: '#bdbdbd',
    height: 130,
    padding: 35,
    textAlign: 'center'
  },
  noFileSelected: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  fileFolderText: {
    color: '#6a7070',
    '&:hover': {
      fontWeight: 'bold',
      color: '#005d83',
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationColor: '#005d83'
    }
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13,
    overflowWrap: 'break-word'
  },
  uploadBox: {
    height: 208
  },
  contentHeight: {
    padding: 12,
    '& .MuiGrid-container': {
      height: 186,
      '& .MuiTableContainer-root': {
        height: 'inherit'
      }
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  sticky: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 1,
    zIndex: 99
  }
});

const webkitdirAttr = { webkitdirectory: 'true' };

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, toolTip, uploadLoaderValue, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <TooltipCustom title={toolTip}>
          <IconButton disabled={uploadLoaderValue} aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </TooltipCustom>
      ) : null}
    </MuiDialogTitle>
  );
});

@inject('upload', 'getBuketFoldersStatus', 'deleteBuketFilesStatus', 'notificationState')
@observer
// eslint-disable-next-line react/prefer-stateless-function
class UploadContent extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    upload: shapes.modelOf(Upload).isRequired,
    language: shapes.language.isRequired,
    openUploadContent: PropTypes.bool.isRequired,
    isMobileSized: PropTypes.bool.isRequired,
    windowHeight: PropTypes.number.isRequired,
    closeUploadContent: PropTypes.func.isRequired,
    chooseFileHandle: PropTypes.func.isRequired,
    getBuketFoldersStatus: shapes.state.isRequired,
    deleteBuketFilesStatus: shapes.state.isRequired,
    notificationState: shapes.state.isRequired,
    chooseFileHandleStatus: PropTypes.bool.isRequired,
    uploadContentPath: PropTypes.string.isRequired,
    uploadEpubContentPath: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    const { upload, uploadContentPath, uploadEpubContentPath } = this.props;
    this.state = {
      uploadFiles: [],
      deleteFiles: [],
      openConfirmationDialog: false,
      bucketFiles: upload.bucketFolders ? upload.bucketFolders : [],
      uploadLoader: false,
      isCheckedAll: false,
      identifierName: null,
      identifierPath: uploadContentPath,
      uuid: null,
      spreadSheetPath: uploadEpubContentPath
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleBreadcrumb = this.handleBreadcrumb.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    const { upload } = this.props;
    const { identifierPath, spreadSheetPath } = this.state;
    if (identifierPath) {
      const ingestPath = 'ingest/';
      upload.getBucketfolders({
        browseData: `${identifierPath}${ingestPath}`
      });
      const identifier = identifierPath.replace(/^\/|\/$/g, '').split('/');
      identifier.splice(0, identifier.length - 1);
      this.setState({ identifierPath: `${identifierPath}${ingestPath}`, identifierName: identifier[0] });
    } else {
      upload.createBucketFolders({ directory: spreadSheetPath, isBulkUtility: true });

      upload.getBucketfolders({
        browseData: `${spreadSheetPath}`
      });
      const path = spreadSheetPath.replace(/^\/|\/$/g, '').split('/');
      path.splice(0, path.length - 1);
      this.setState({ spreadSheetPath: `${spreadSheetPath}`, uuid: path[0] });
    }
  }

  handleDownload = async (fileName) => {
    const { notificationState } = this.props;
    const { identifierPath } = this.state;
    let signedUrl = '';
    const param = {
      contentPath: `${identifierPath}${fileName}`,
      type: 'download'
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.CM_GET_SIGNED_URL}`, param);
    if (response && response.data && response.data.data) {
      signedUrl = response.data.data;
      const aTag = document.createElement('a');
      aTag.setAttribute('download', '');
      aTag.setAttribute('href', signedUrl);
      aTag.click();
    } else {
      const notification = {
        open: true,
        message: `Error downloading '${fileName}'. Please try again !`,
        type: 'error'
      };
      notificationState.set(notification);
    }
  };

  handleUpload = () => {
    const { upload, chooseFileHandle, chooseFileHandleStatus } = this.props;
    const { identifierPath, spreadSheetPath, uploadFiles } = this.state;
    let signedUrl = '';
    const uploadedFilesDetails = uploadFiles;
    this.setState({ uploadLoader: true });
    try {
      uploadFiles.forEach(async (file) => {
        const param = {
          contentPath: identifierPath ? `${identifierPath}${file.uploadKey}` : `${spreadSheetPath}${file.uploadKey}`,
          type: 'upload'
        };
        const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.CM_GET_SIGNED_URL}`, param);
        const options = {
          headers: {
            'Content-Type': file.selectedFiles.type
          }
        };
        if (response && response.data && response.data.data) {
          signedUrl = response.data.data;
        }
        await axios.put(signedUrl, file.selectedFiles, options);
        const uploadedFileIndex = _.indexOf(uploadedFilesDetails, file);
        uploadedFilesDetails.splice(uploadedFileIndex, 1);
        if (uploadedFilesDetails.length === 0) {
          if (identifierPath) {
            upload.getBucketfolders({
              browseData: identifierPath
            });
          } else {
            upload.getBucketfolders({
              browseData: spreadSheetPath
            });
          }
          Framework.getEventManager().on(constants.CM_BUCKET_FOLDERS, () => {
            this.setState({ uploadFiles: [], uploadLoader: false });
          });
          if (chooseFileHandleStatus) {
            const fileValue = file.path;
            chooseFileHandle(fileValue);
          }
        }
      });
    } catch (error) {
      this.setState({ uploadFiles: [], uploadLoader: false });
    }
  };

  handleFilesDelete = (file) => {
    const { uploadFiles } = this.state;
    const finalFiles = uploadFiles;
    const fileIndex = _.indexOf(finalFiles, file);
    finalFiles.splice(fileIndex, 1);
    this.setState({ uploadFiles: finalFiles });
  };

  handleDelete = () => {
    this.setState({ openConfirmationDialog: true });
  };

  handleConfirm = (confirmResponse) => {
    const { upload } = this.props;
    const { deleteFiles } = this.state;
    this.setState({ openConfirmationDialog: false });
    if (confirmResponse) {
      upload.deleteBucketFiles({
        deleteData: deleteFiles
      });
      this.setState({ deleteFiles: [], isCheckedAll: false });
    }
  };

  handleFolder = (fileName) => {
    const { upload } = this.props;
    const { identifierPath } = this.state;
    upload.getBucketfolders({
      browseData: `${identifierPath}${fileName}`
    });

    this.setState({ identifierPath: `${identifierPath}${fileName}` });
  };

  handleBreadcrumb = (folderPath) => {
    const { upload } = this.props;
    const { identifierPath } = this.state;
    upload.getBucketfolders({
      browseData: folderPath
    });

    if (identifierPath) {
      this.setState({ identifierPath: folderPath });
    } else {
      this.setState({ spreadSheetPath: folderPath });
    }
  };

  handleCheckBox = (event, isInputChecked) => {
    const { identifierPath, deleteFiles } = this.state;
    const path = deleteFiles;
    if (isInputChecked) {
      path.push(`${identifierPath}${event.target.name}`);
      this.setState({
        deleteFiles: path
      });
    } else {
      const checkedIndex = _.indexOf(path, `${identifierPath}${event.target.name}`);
      path.splice(checkedIndex, 1);
      this.setState({
        deleteFiles: path
      });
    }
  };

  handleAllCheckBox = (event, isInputChecked) => {
    const { bucketFiles, identifierPath } = this.state;
    if (isInputChecked) {
      const path = [];
      bucketFiles.forEach((file) => {
        path.push(`${identifierPath}${file}`);
      });
      this.setState({ deleteFiles: path, isCheckedAll: true });
    } else {
      this.setState({ deleteFiles: [], isCheckedAll: false });
    }
  };

  handleSelect = (input) => {
    const { uploadFiles } = this.state;
    let selectedFiles = '';
    let response = uploadFiles;
    if (input && input.currentTarget && input.currentTarget.files) {
      selectedFiles = input.currentTarget.files;
    } else {
      selectedFiles = input;
    }

    for (let i = 0; i < selectedFiles.length; i += 1) {
      if (selectedFiles[i].webkitRelativePath !== '') {
        const folderPath = selectedFiles[i].webkitRelativePath.split(
          selectedFiles[i].path ? selectedFiles[i].path.replace(/^\//, '') : selectedFiles[i].name
        );
        folderPath.pop();

        response.push({
          folder: folderPath,
          path: selectedFiles[i].name,
          type: selectedFiles[i].type,
          size: selectedFiles[i].size,
          uploadKey: selectedFiles[i].webkitRelativePath,
          selectedFiles: selectedFiles[i]
        });
      } else {
        let folderPath = null;
        if (selectedFiles[i].path && selectedFiles[i].path.includes('/')) {
          const filePathDetail = selectedFiles[i].path.replace(/^\//, '');
          folderPath = filePathDetail.split(selectedFiles[i].name);
          folderPath.pop();
        }

        response.push({
          folder: folderPath,
          path: selectedFiles[i].name,
          type: selectedFiles[i].type,
          size: selectedFiles[i].size,
          uploadKey: selectedFiles[i].path ? selectedFiles[i].path.replace(/^\//, '') : selectedFiles[i].name,
          selectedFiles: selectedFiles[i]
        });
      }
    }

    response = _.uniqBy(response, (o) => o.uploadKey);
    this.setState({ uploadFiles: response });
  };

  render() {
    const {
      classes,
      openUploadContent,
      closeUploadContent,
      language,
      getBuketFoldersStatus,
      deleteBuketFilesStatus,
      isMobileSized,
      windowHeight
      // chooseFileHandleStatus
      // chooseFileHandle
    } = this.props;
    const {
      identifierPath,
      spreadSheetPath,
      uploadFiles,
      deleteFiles,
      bucketFiles,
      openConfirmationDialog,
      uploadLoader,
      identifierName,
      isCheckedAll,
      uuid
    } = this.state;

    let tableHeight = 'inherit';
    if (!isMobileSized && windowHeight >= 500) {
      tableHeight = windowHeight - 335;
    }

    return (
      <>
        {openConfirmationDialog && (
          <ConfirmationDialog
            message={{ ...language.getText('upload.DELETE_CONFIRMATION') }.defaultMessage}
            open
            callback={this.handleConfirm}
          />
        )}
        <Paper>
          <Dialog
            disableEscapeKeyDown={uploadLoader}
            fullScreen
            open={openUploadContent}
            onClose={closeUploadContent()}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={closeUploadContent()}
              align="center"
              toolTip={<FormattedMessage {...language.getText('common.CLOSE')} />}
              uploadLoaderValue={uploadLoader}
            >
              <Typography variant="h2">
                <FormattedMessage {...language.getText('contentmanager.UPLOAD_CONTENT')} />
              </Typography>
            </DialogTitle>
            <LoadingHandler
              loading={getBuketFoldersStatus.isPending || deleteBuketFilesStatus.isPending || uploadLoader}
              isError={
                (getBuketFoldersStatus.isError &&
                  getBuketFoldersStatus.error &&
                  getBuketFoldersStatus.error.status === 401) ||
                (deleteBuketFilesStatus.isError &&
                  deleteBuketFilesStatus.error &&
                  deleteBuketFilesStatus.error.status === 401)
              }
              errorContent={<StateErrorDisplay error={getBuketFoldersStatus.error} showDetails />}
              content={
                <>
                  <DialogContent>
                    <Box mb={1}>
                      <Box mb={1}>
                        {identifierPath ? (
                          <Breadcrumb
                            path={identifierPath}
                            identifierName={identifierName}
                            handleBreadcrumb={(folderPath) => this.handleBreadcrumb(folderPath)}
                            handleUpload={uploadLoader}
                          />
                        ) : (
                          <Breadcrumb
                            sheetPath={spreadSheetPath}
                            uuid={uuid}
                            handleBreadcrumb={(folderPath) => this.handleBreadcrumb(folderPath)}
                            handleUpload={uploadLoader}
                          />
                        )}
                      </Box>
                      <Grid spacing={1} justify="space-between" container direction="row">
                        <Grid item sm={5} xs={12}>
                          <Paper elevation={3} className={classes.uploadBox}>
                            <Box p={1}>
                              <Dropzone onDrop={this.handleSelect}>
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps({ className: classes.dropzone })}>
                                    <input {...getInputProps()} />
                                    <p>
                                      <FormattedMessage {...language.getText('common.DRAG_&_DROP')} />
                                    </p>
                                  </div>
                                )}
                              </Dropzone>
                            </Box>
                            <Grid container direction="row" justify="space-evenly">
                              <label htmlFor="btn-selectFiles">
                                <input
                                  id="btn-selectFiles"
                                  name="btn-selectFiles"
                                  style={{ display: 'none' }}
                                  type="file"
                                  multiple
                                  onChange={this.handleSelect}
                                />
                                <Button
                                  className={`btn-choose ${classes.buttonSmall}`}
                                  variant="outlined"
                                  startIcon={<FileIcon />}
                                  component="span"
                                >
                                  <FormattedMessage {...language.getText('contentmanager.CHOOSEFILES')} />
                                </Button>
                              </label>
                              {identifierPath ? (
                                <label htmlFor="btn-selectFolder">
                                  <input
                                    id="btn-selectFolder"
                                    name="btn-selectFolder"
                                    style={{ display: 'none' }}
                                    type="file"
                                    {...webkitdirAttr}
                                    onChange={this.handleSelect}
                                  />
                                  <Button
                                    variant="outlined"
                                    className={`btn-choose ${classes.buttonSmall}`}
                                    startIcon={<FolderIcon />}
                                    component="span"
                                  >
                                    <FormattedMessage {...language.getText('contentmanager.CHOOSEFOLDERS')} />
                                  </Button>
                                </label>
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item sm={7} xs={12}>
                          <Paper elevation={3} className={classes.uploadBox}>
                            <Box className={classes.contentHeight}>
                              <Grid container direction="column">
                                {uploadFiles && uploadFiles.length > 0 ? (
                                  <TableContainer component={Paper}>
                                    <Table
                                      className={classes.tableRoot}
                                      stickyHeader
                                      aria-label="File/Folder List table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center" style={{ maxWidth: '30%' }}>
                                            <Typography variant="h6">
                                              <FormattedMessage {...language.getText('label.FOLDER')} />
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ maxWidth: '30%' }}>
                                            <Typography variant="h6">
                                              <FormattedMessage {...language.getText('label.FILE')} />
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ maxWidth: '20%' }}>
                                            <Typography variant="h6">
                                              <FormattedMessage {...language.getText('label.TYPE')} />
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="center" style={{ maxWidth: '10%' }}>
                                            <Typography variant="h6">
                                              <FormattedMessage {...language.getText('label.SIZE')} />
                                            </Typography>
                                          </TableCell>
                                          <TableCell align="right" style={{ maxWidth: '10%' }}>
                                            <Button
                                              className={classes.buttonSmall}
                                              disabled={!(uploadFiles && uploadFiles.length > 0)}
                                              onClick={this.handleUpload}
                                              variant="outlined"
                                              color="primary"
                                              component="span"
                                              startIcon={<CloudUploadIcon />}
                                            >
                                              <FormattedMessage {...language.getText('contentmanager.UPLOAD')} />
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {uploadFiles.map((fileName) => (
                                          <TableRow>
                                            <TableCell zeroMinWidth>
                                              {fileName.folder ? (
                                                <Typography
                                                  style={{ wordBreak: 'break-all' }}
                                                  className={classes.tableText}
                                                >
                                                  {fileName.folder}
                                                </Typography>
                                              ) : (
                                                '-'
                                              )}
                                            </TableCell>
                                            <TableCell zeroMinWidth>
                                              <Typography
                                                style={{ wordBreak: 'break-all' }}
                                                className={classes.tableText}
                                              >
                                                {fileName.path}
                                              </Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography className={classes.tableText}>{fileName.type}</Typography>
                                            </TableCell>
                                            <TableCell>
                                              <Typography className={classes.tableText}>
                                                {fileName && utils.convertBytes(fileName.size)}
                                              </Typography>
                                            </TableCell>
                                            <TableCell key={fileName}>
                                              <TooltipCustom
                                                id="remove-tooltip"
                                                placement="bottom"
                                                title={<FormattedMessage {...language.getText('common.DELETE')} />}
                                              >
                                                <IconButton
                                                  aria-label="Delete selected"
                                                  onClick={() => this.handleFilesDelete(fileName)}
                                                >
                                                  <DeleteForeverIcon />
                                                </IconButton>
                                              </TooltipCustom>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                ) : (
                                  <TableBody>
                                    <Grid align="center">
                                      <Grid justify="center" container direction="column">
                                        <Grid>
                                          <Typography variant="h5">
                                            <FormattedMessage {...language.getText('common.NO_FILE_FOLDER')} />
                                          </Typography>
                                        </Grid>
                                        <Grid>
                                          <Typography className={classes.noFileSelected}>
                                            <FormattedMessage {...language.getText('common.NOT_CHOOSEN_FILE_FOLDER')} />
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TableBody>
                                )}
                              </Grid>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Box>
                    <Paper elevation={3}>
                      <TableContainer style={{ 'max-height': tableHeight }}>
                        <Table size="small" stickyHeader aria-label="File/Folder List table">
                          <TableHead>
                            <TableCell>
                              <Checkbox color="primary" checked={isCheckedAll} onChange={this.handleAllCheckBox} />
                            </TableCell>
                            <TableCell align="center">
                              <Typography align="left" variant="h5">
                                <FormattedMessage {...language.getText('hotspot.NAME')} />
                              </Typography>
                            </TableCell>
                            {/* <TableCell align="right">
                              <Button
                                variant="outlined"
                                color="primary"
                                className={classes.buttonSmall}
                                onClick={this.handleDelete}
                                disabled={!(deleteFiles.length > 0)}
                                startIcon={<DeleteForeverIcon />}
                              >
                                {deleteFiles.length > 0 ? 'Delete Selected' : 'Delete'}
                              </Button>
                            </TableCell> */}
                          </TableHead>
                          <TableBody>
                            {bucketFiles.map((fileName) => (
                              <TableRow>
                                <TableCell>
                                  <Checkbox
                                    color="primary"
                                    name={fileName}
                                    checked={deleteFiles.includes(`${identifierPath}${fileName}`)}
                                    onChange={this.handleCheckBox}
                                  />
                                </TableCell>
                                <TableCell key={fileName}>
                                  <Typography
                                    className={fileName.endsWith('/') ? classes.fileFolderText : null}
                                    onClick={() => (fileName.endsWith('/') ? this.handleFolder(fileName) : null)}
                                    align="left"
                                  >
                                    {fileName}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  {!fileName.endsWith('/') && identifierPath && (
                                    <Button
                                      variant="outlined"
                                      className={classes.buttonSmall}
                                      onClick={() => this.handleDownload(fileName)}
                                      startIcon={<CloudDownloadIcon />}
                                    >
                                      <FormattedMessage {...language.getText('common.DOWNLOAD')} />
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </DialogContent>
                </>
              }
              loadingContent={
                <Loader
                  primaryMessage={uploadLoader ? 'This might take a while based on the size of the content.' : null}
                  secondaryMessage={uploadLoader ? 'Please do not close or refresh the window.' : null}
                />
              }
            />
          </Dialog>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(inject('language')(withWindowDimensions(UploadContent)));
