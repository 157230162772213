const filterByValues = [
  {
    name: 'title',
    label: 'Title'
  },
  {
    name: 'author',
    label: 'Author'
  },
  {
    name: 'bookID',
    label: 'Book ID'
  },
  {
    name: 'globalBookID',
    label: 'Global Book ID'
  },
  {
    name: 'isbn',
    label: 'ISBN (10,13)'
  },
  {
    name: 'moduleID',
    label: 'Module ID'
  },
  {
    name: 'productID',
    label: 'Product ID'
  }
];

const searchConfig = {
  filterByValues
};

module.exports = searchConfig;
