import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const responseObj = types.model('responseObj', {
  token: types.maybeNull(types.string),
  expiresIn: types.maybeNull(types.number)
});

const GetPiTokenModel = types
  .model('GetPiToken', {
    status: types.maybeNull(types.string),
    data: types.maybeNull(types.optional(responseObj, {}))
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_PI_TOKEN_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_PI_TOKEN_DATA);
    },
    resetStoreValues() {
      const initialState = {};
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_PI_TOKEN_DATA);
    }
  }));

export default GetPiTokenModel;
