/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2024 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import { withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../../common/header/Header';
import ValidatePermissions from '../users/ValidatePermissions';
import ServiceNow from './components/ServiceNow';

const styles = () => ({
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    height: 'calc(100% - 64px)'
  }
});

@inject()
@observer
class ServiceNowContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  render() {
    const { classes } = this.props;
    const showDashboardEnabled = false;
    return (
      <>
        <Header />
        <main className={classes.contentWrapper}>
          <ValidatePermissions givenPermissions="admin_can_view" showDashboardEnabled={showDashboardEnabled}>
            <Route path="/servicenow" exact render={() => <ServiceNow {...this.props} />} />
          </ValidatePermissions>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(ServiceNowContainer);
